<script>
	import { onMount } from "svelte";
	var mouseover = 0;
	var buyShirt = 0;
	var infoClicked = 0;
	Object.defineProperty(HTMLMediaElement.prototype, 
	'playing', {
		get: function() {
			return !!(this.currentTime > 0 && !this.paused && !this.ended && this.readyState > 2);
		}
	});
	onMount(() => {
		var cursor = document.querySelector(".cursor");
		var main = document.querySelector(".main");

		var x = 0;
		var y = 0;
		document.addEventListener("mousemove", function (e) {
			x = e.pageX;
			y = e.pageY;
		});
		setInterval(function () {
			cursor.style.top = y + "px";
			cursor.style.left = x + "px";
		}, 50);

		document.addEventListener("mousedown", () => {
			cursor.classList.add("click");
		});

		var videoElement = document.getElementById('background-video');
		if(!videoElement.playing){
			videoElement.play();
		}
		videoElement.addEventListener('suspend', (e) => {
			e.preventDefault();
			videoElement.play();
		});

		document.body.onclick = function () {
			if (!videoElement.playing) {
				videoElement.play();
			} 
		}
	});

	const mouseenter = (e) => {
		mouseover = 1;
	};

	const mouseleave = (e) => {
		mouseover = 0;
	};
</script>

<svelte:head>
	<title>VALLEY TRASH | Shop</title>
</svelte:head>

<main class="main">
	<video id="background-video" autoplay="autoplay" loop="loop" muted defaultMuted playsinline  oncontextmenu="return false;"  preload="auto" >
		<source src="https://mainsite-bg-vid-1.s3.amazonaws.com/vt-bg.mp4" type="video/mp4">
	</video>
	<div class="cursor" class:mouseover />
	{#if !buyShirt && !infoClicked}
	<div id="init-wrap">
		<a id="initialbtn" on:mouseenter={mouseenter} on:mouseleave={mouseleave}>
			SOLD OUT
		</a>
		<a id="info-btn" on:click={() => infoClicked = 1} on:mouseenter={mouseenter} on:mouseleave={mouseleave}>
			what is this?
		</a>
	</div>
	{:else if infoClicked}
	<div class="link-wrap">
		<a id="info-p">Here at ValleyTrash, our team of painfully inexperienced, but enthusiastic, designers spend 45 minutes to an hour hand-stamping each shirt, making every order one-of-a-kind.</a>
		<a id="info-p">We then send your shirt to a committee of the most attractive French pool boys you’ve ever seen who package the shirts into a sleek plastic envelope purchased from Daddy Bezos himself.</a>
		<a id="info-p">Lastly we walk our shirts to the Tarzana USPS location on Yolanda and Burbank Blvd - as we don’t trust a silly mailman with such precious cargo. We pray that this fails as this business model is incredibly unsustainable and we can’t afford to pay the French pool boys for much longer. Thank you.</a>
		<a id="backbtn-skyrim" on:click={() => location.reload()} on:mouseenter={mouseenter} on:mouseleave={mouseleave}>back</a>
	</div>
	{:else}
	<div class="link-wrap">
	<div class="links">
		<a
			href="https://square.link/u/nSsgI8Nz"
			id="small"
			target="_"
			on:mouseenter={mouseenter}
			on:mouseleave={mouseleave}
		>
			small
		</a>
		<a
			id="medium"
			on:mouseenter={mouseenter}
			on:mouseleave={mouseleave}
			href="https://square.link/u/0RC6UlI3"
			target="_"
		>
			medium
		</a>
		<a
			id="large"
			on:mouseenter={mouseenter}
			on:mouseleave={mouseleave}
			href="https://square.link/u/1mEUNFVs"
			target="_"
		>
			large
		</a>
		<a
			id="extra-large"
			on:mouseenter={mouseenter}
			on:mouseleave={mouseleave}
			href="https://square.link/u/v0IprBvC"
			target="_"
		>
			extra large
		</a>
	</div>
	<a id="backbtn" on:click={() => location.reload()} on:mouseenter={mouseenter} on:mouseleave={mouseleave}>back</a>
	</div>
	{/if}
	{#if infoClicked}
	<p style="font-family: 'O Princeps'; font-size:8pt;">TEZAN RECORDS © 2022</p>
	{:else}
	<p>TEZAN RECORDS © 2022</p>
	{/if}
</main>

<style>
	main {
		cursor: none;
		/* background-image: url("/bg-min.jpg");
		background-size: 800px 800px; */
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 100%;
		min-width: 0px;
		box-shadow: 0px 0px 100px 10px rgba(0, 0, 0, 0.382) inset;
	}
	#init-wrap {
		display: flex;
		flex-direction: column;
	}
	#info-btn {
		font-size: 12pt;
		margin-top: -0.8rem;
		font-family: "O Princeps";
		width: 8rem;
		margin: 0 auto;
		text-align: center;
	}
	#backbtn, #backbtn-skyrim{
		font-size: 10pt;
		width: 5rem;	
		margin: 0 auto;
		text-align: center;
	}

	#backbtn-skyrim {
		font-family: "O Princeps";
		font-size: 14pt;
	}
	.link-wrap {
		display: flex;
		flex-direction: column;
	}
	#background-video {
		width: 100vw;
		height: 100vh;
		object-fit: cover;
		position: fixed;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		z-index: -1;
	}
	.links {
		width: 600px;
		display: flex;
		justify-content: center;
		margin-bottom: 3rem;
		flex-wrap: wrap;
	}
	.cursor {
		width: 40px;
		height: 40px;
		border-radius: 100%;
		box-shadow: 0px 0px 10px black;
		transition: all 100ms ease-out;
		position: absolute;
		pointer-events: none;
		transform: translate(calc(-50% + 15px), -50%);
		background-color: black;
		opacity: 0.5;
	}

	.mouseover {
		width: 65px;
		height: 65px;
	}
	p {
		position: absolute;
		bottom: 1rem;
		font-family: "R Grunge";
		font-size: 8px;
		color: white;	
		text-shadow: 0px 0px 5px rgba(255, 255, 255, 0.8);
	}
	#info-p {
		position: relative;
		font-size: 13pt;
		margin-bottom: 2rem;
		width: 70%;
		margin: 0 auto;
		z-index: -1;
		font-family: "O Princeps";
	}
	a {
		font-weight: 900;
		font-size: 1.5rem;
		text-align: center;
		color: black;
		color: white;
		text-shadow: 0px 0px 5px rgba(255, 255, 255, 0.8);
		text-decoration: none;
		padding: 1rem;
		font-family: "R Grunge";
	}

	#small {
		color: rgb(29, 117, 73);
			color: white;
		text-shadow: 0px 0px 5px rgba(29, 117, 73, 0.486);
		text-shadow: 0px 0px 5px rgba(255, 255, 255, 0.8);
	}


	#large {
		color: rgb(250, 114, 114);
			color: white;
		text-shadow: 0px 0px 5px rgba(250, 114, 114, 0.486);
		text-shadow: 0px 0px 5px rgba(255, 255, 255, 0.8);
	}

	#extra-large{
		color: rgb(151, 238, 255);
			color: white;
		text-shadow: 0px 0px 5px rgba(173, 241, 255, 0.486);
		text-shadow: 0px 0px 5px rgba(255, 255, 255, 0.8);
	}

	#large:hover{
		color: white;
	}

	#small:hover {
		color: white;
	}

	#extra-large:hover {
		color: white;
	}

	a:hover {
		cursor: none;
		color: white;
	}

	@supports (
		(-webkit-backdrop-filter: invert(100%)) or
			(backdrop-filter: invert(100%))
	) {
		.cursor {
			background-color: transparent;
			opacity: 1;
			backdrop-filter: invert(100%) contrast(110%);
			-webkit-backdrop-filter: invert(100%) contrast(110%);
		}

		a:hover {
			color: black;
			color: white;
			cursor: none;
		}

		#extra-large:hover{
			color: rgb(173, 241, 255);
			color: white;
			cursor: none;
		}

		#small:hover {
			color:rgb(29, 117, 73);
			color: white;
			cursor: none;
		}

		#large:hover{
			color:rgb(250, 114, 114);
			color: white;
			cursor: none;
		}
	}

	@media (max-width: 600px) {
		.links {
			flex-direction: column;
			align-items: center;
			justify-content: space-between;
			width: 100%;
			height: 200px;
			margin-bottom: 0px;
		}
		a {
			text-align: center;
			padding: 0;
		}
		#backbtn, #backbtn-skyrim{
			margin-top: 2rem;
		}
		#info-btn{
			margin-top: 1rem;
		}
		#info-p {
			width: 80%;
			font-size: 11pt;
			margin: 0 auto;
	
		}
	}
	* {
		user-select: none;
		box-sizing: border-box;
		overflow: hidden;
	}
</style>
